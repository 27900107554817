<template>
  <div class="footer">
    <div class="logo">
      <img class="titlelogo" src="../assets/logo.png" />
      <div class="title">
        不同水质 不同配方
      </div>
      <img class="qrcodeimg" src="../assets/serviceqrcode.png" />
      <div class="qrcode">
        <span>U2官方旗舰店</span>
      </div>
    </div>

    <div class="linestyle"></div>

    <div class="copyright">
      <span class="right">
        源自法国 始于1936
      </span>
      <span class="engtext">
        Copyright © 2019, youtuqingjieyongpin Co., Ltd. All Rights Reserved.
      </span>
      <span class="chatext">
        浙江U2清洁用品有限公司版权所有 浙ICP备12003180号
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goMap() {
      window.open("https://j.map.baidu.com/e2/Por");
    },
    goBeian() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802010555"
      );
    },
    goICP() {
      window.open("http://beian.miit.gov.cn");
    }
  }
};
</script>

<style lang="scss">
.footer {
  position: relative;
  // height: 1.8rem;
  color: #212121;
  font-size: 0.3rem;
  // padding: 0 2rem;
  background: #f7f7f7;
  .logo {
    position: relative;
    height: 100%;
    display: grid;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;

    top: 0.5rem;

    .titlelogo {
      width: 1rem;
      top: 0.2rem;
      position: absolute;
    }

    .title {
      width: 100%;
      text-align: center;
      margin-top: 3%;
      line-height: 0.8rem;
      color: #dfb25f;
      position: absolute;
      top: 1rem;
      font-size: 0.3rem;
    }

    .qrcodeimg {
      width: 2.2rem;
      text-align: center;
      position: absolute;
      top: 2.4rem;
    }

    .qrcode {
      width: 100%;
      margin-top: 14%;
      text-align: center;
      position: absolute;
      top: 3.3rem;
    }
  }

  .copyright {
    position: absolute;
    height: 4rem;
    width: 100%;
    top: 5.6rem;
    display: grid;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    font-size: 0.3rem;

    .right {
      width: 100%;
      text-align: center;
      font-size: 0.36rem;
      line-height: 1rem;
    }

    .engtext {
      padding-top: 0.5rem;
      font-size: 0.25rem;
    }

    .chatext {
      padding-top: 0.15rem;
      font-size: 0.22rem;
    }
  }

  .linestyle {
    position: relative;
    top: 7.6rem;
    height: 1px;
    background: #e6e6e6;
  }
}
</style>
