<template>
  <div class="section2">
    <div class="prdimgs">
      <div
        class="prdimgitem"
        :class="{ 'prdimgitem-checked': imgIndex == 0 }"
      >
        <img src="./imgs/section2-prd-1.png" @click="selectimg(0, 0)" />
      </div>
      <div
        class="prdimgitem"
        :class="{ 'prdimgitem-checked': imgIndex == 1 }"
      >
        <img src="./imgs/section2-prd-2.png" @click="selectimg(1, 1)" />
      </div>
      <div
        class="prdimgitem"
        :class="{ 'prdimgitem-checked': imgIndex == 2 }"
      >
        <img src="./imgs/section2-prd-3.png" @click="selectimg(2, 2)" />
      </div>
      <div
        class="prdimgitem"
        :class="{ 'prdimgitem-checked': imgIndex == 3 }"
      >
        <img src="./imgs/section2-prd-5.png" @click="selectimg(3, 2)" />
      </div>
      <div
        class="prdimgitem"
        :class="{ 'prdimgitem-checked': imgIndex == 4 }"
      >
        <img src="./imgs/section2-prd-6.png" @click="selectimg(4, 0)" />
      </div>
      <div
        class="prdimgitem"
        :class="{ 'prdimgitem-checked': imgIndex == 5 }"
      >
        <img src="./imgs/section2-prd-7.png" @click="selectimg(5, 1)" />
      </div>
      <div
        class="prdimgitem"
        :class="{ 'prdimgitem-checked': imgIndex == 6 }"
      >
        <img src="./imgs/section2-prd-8.png" @click="selectimg(6, 2)" />
      </div>
      <div
        class="prdimgitem"
        :class="{ 'prdimgitem-checked': imgIndex == 7 }"
      >
        <img src="./imgs/section2-prd-9.png" @click="selectimg(7, 0)" />
      </div>
      <!-- <div class="prdimgitem">
        <img src="./imgs/section2-prd-4.png" @click="selectimg(3)"/>
      </div> -->
    </div>
    <div class="prdtext">
      <span class="title">{{ item[this.imgIndex].title }}</span>
      <span class="label">{{ item[this.imgIndex].label }}</span>
      <span class="content">
        {{ item[this.imgIndex].content }}
      </span>
      <span class="empty" />
      <span class="content">{{ item[this.imgIndex].title1 }}</span>
      <span class="content">
        {{ item[this.imgIndex].content1 }}
      </span>
      <span class="empty" />
      <span class="content">{{ item[this.imgIndex].title2 }}</span>
      <span class="content">{{ item[this.imgIndex].content2 }}</span>
      <span class="empty" />
      <span class="content">{{ item[this.imgIndex].title5 }}</span>
      <span class="content">{{ item[this.imgIndex].content5 }}</span>
      <span class="empty" />
      <span class="content">{{ item[this.imgIndex].title3 }}</span>
      <span class="content">
        {{ item[this.imgIndex].content3 }}
      </span>
      <span class="empty" />
      <span class="content">{{ item[this.imgIndex].title4 }}</span>
      <span class="content">{{ item[this.imgIndex].content4 }}</span>
      <span class="empty" />
      <span class="tip">
        {{ item[this.imgIndex].tip }}
      </span>
      <span class="tip">
        {{ item[this.imgIndex].tip1 }}
      </span>
      <span class="empty" />
      <span class="tip">{{ item[this.imgIndex].tip2 }}</span>
      <span class="empty" />
      <span class="label">{{ item[this.imgIndex].label1 }}</span>
      <div class="box">
        <div
          class="boxitem"
          v-for="(boxItem, index) in item[this.imgIndex].skuList"
          :key="index"
          :class="{ 'boxitem-checked': currentSkuIndex == index }"
          @click="goSelSku(index, boxItem.imgIndex)"
        >
          {{ boxItem.boxitem }}
        </div>
      </div>
      <span class="label">{{ item[this.imgIndex].label2 }}</span>
      <div class="box">
        <div
          class="boxitem"
          v-for="(boxItem, index) in item[this.imgIndex].skuSizeList"
          :key="index"
          :class="{ 'boxitem-checked': currentSizeIndex == index }"
          @click="goSelSkuSize(index)"
        >
          {{ boxItem.boxitem }}
        </div>
      </div>
      <span class="label">{{ item[this.imgIndex].label3 }}</span>
      <div class="box">
        <div
          class="boxitem"
          v-for="(boxItem, index) in item[this.imgIndex].skuTypeList"
          :key="index"
          :class="{ 'boxitem-checked': currentTypeIndex == index }"
          @click="goSelSkuType(index)"
        >
          {{ boxItem.boxitem }}
        </div>
      </div>
      <span class="empty" />

      <div class="box">
        <button class="btn" @click="openJingDong">
          <img class="btnimg" src="./imgs/jingdong.png" />
          京东
        </button>
        <button class="btn" @click="openTianMao">
          <img class="btnimg" src="./imgs/tianmao.png" />
          天猫
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: [
        {
          title: "洗衣机清洁剂",
          label: "产品特点",
          content:
            "专研不同水质不同配方，更好地溶解特定残留物。7效合1——灭活流感、冠状病毒, 杀灭细菌、杀灭真菌、筒壁除垢、祛除内筒异味、清洁内筒，一瓶解决多种洗衣机清洁卫生问题。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）、冠状病毒（Hcov-229E）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title3: "精准洁净：",
          content3:
            "不同水质不同配方，有效解决不同水质中矿物沉积不同而带来的水垢差异，保证内筒的清洁效果。",
          title4: "留香祛味：",
          content4: "法国调香大师赋予白棉清芬，留香祛味",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒、Hcov-229E冠状病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌均具有杀灭作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          label1: "产品规格",
          label2: "产品容量",
          label3: "产品香型",
          skuList: [
            { boxitem: "TDS < 250", imgIndex: 0 },
            { boxitem: "TDS200-450", imgIndex: 1 },
            { boxitem: "TDS > 400", imgIndex: 2 }
          ],
          skuSizeList: [{ boxitem: "250ml/瓶" }],
          skuTypeList: [{ boxitem: "白棉清芬" }]
        },
        {
          title: "洗衣机清洁剂2",
          label: "产品特点",
          content:
            "专研不同水质不同配方，更好地溶解特定残留物。7效合1——灭活流感、冠状病毒, 杀灭细菌、杀灭真菌、筒壁除垢、祛除内筒异味、清洁内筒，一瓶解决多种洗衣机清洁卫生问题。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）、冠状病毒（Hcov-229E）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title3: "精准洁净：",
          content3:
            "不同水质不同配方，有效解决不同水质中矿物沉积不同而带来的水垢差异，保证内筒的清洁效果。",
          title4: "留香祛味：",
          content4: "法国调香大师大师赋予白棉清芬，留香祛味",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒、Hcov-229E冠状病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌均具有杀灭作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          label1: "产品规格",
          label2: "产品容量",
          label3: "产品香型",
          skuList: [
            { boxitem: "TDS < 250", imgIndex: 0 },
            { boxitem: "TDS200-450", imgIndex: 1 },
            { boxitem: "TDS > 400", imgIndex: 2 }
          ],
          skuSizeList: [{ boxitem: "250ml/瓶" }],
          skuTypeList: [{ boxitem: "白棉清芬" }]
        },
        {
          title: "洗衣机清洁剂3",
          label: "产品特点",
          content:
            "专研不同水质不同配方，更好地溶解特定残留物。7效合1——灭活流感、冠状病毒, 杀灭细菌、杀灭真菌、筒壁除垢、祛除内筒异味、清洁内筒，一瓶解决多种洗衣机清洁卫生问题。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）、冠状病毒（Hcov-229E）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title3: "精准洁净：",
          content3:
            "不同水质不同配方，有效解决不同水质中矿物沉积不同而带来的水垢差异，保证内筒的清洁效果。",
          title4: "留香祛味：",
          content4: "法国调香大师大师赋予白棉清芬，留香祛味",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒、Hcov-229E冠状病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌均具有杀灭作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          label1: "产品规格",
          label2: "产品容量",
          label3: "产品香型",
          skuList: [
            { boxitem: "TDS < 250", imgIndex: 0 },
            { boxitem: "TDS200-450", imgIndex: 1 },
            { boxitem: "TDS > 400", imgIndex: 2 }
          ],
          skuSizeList: [{ boxitem: "250ml/瓶" }],
          skuTypeList: [{ boxitem: "白棉清芬" }]
        },
        {
          title: "洗衣机清洁剂4",
          label: "产品特点",
          content:
            "专研不同水质不同配方，针对性溶解特定残留物。6效合1——灭活流感病毒, 杀细菌、杀真菌、筒壁除垢、祛除内筒异味、清洁内筒，一瓶解决多种洗衣机清洁卫生问题。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title3: "精准洁净：",
          content3:
            "不同水质不同配方，有效解决不同水质中矿物沉积不同而带来的水垢差异，保证内筒的清洁效果。",
          title4: "留香祛味：",
          content4: "法国调香大师赋予柠夏海风，自然清新",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌均具有杀菌作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          label1: "产品规格",
          label2: "产品容量",
          label3: "产品香型",
          skuList: [
            { boxitem: "TDS < 250", imgIndex: 4 },
            { boxitem: "TDS200-450", imgIndex: 5 },
            { boxitem: "TDS > 400", imgIndex: 3 }
          ],
          skuSizeList: [{ boxitem: "250ml/瓶" }],
          skuTypeList: [{ boxitem: "柠夏海风" }]
        },
        {
          title: "洗衣机清洁剂5",
          label: "产品特点",
          content:
            "专研不同水质不同配方，针对性溶解特定残留物。6效合1——灭活流感病毒, 杀细菌、杀真菌、筒壁除垢、祛除内筒异味、清洁内筒，一瓶解决多种洗衣机清洁卫生问题。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title3: "精准洁净：",
          content3:
            "不同水质不同配方，有效解决不同水质中矿物沉积不同而带来的水垢差异，保证内筒的清洁效果。",
          title4: "留香祛味：",
          content4: "法国调香大师赋予柠夏海风，自然清新",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌均具有杀菌作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          label1: "产品规格",
          label2: "产品容量",
          label3: "产品香型",
          skuList: [
            { boxitem: "TDS < 250", imgIndex: 4 },
            { boxitem: "TDS200-450", imgIndex: 5 },
            { boxitem: "TDS > 400", imgIndex: 3 }
          ],
          skuSizeList: [{ boxitem: "250ml/瓶" }],
          skuTypeList: [{ boxitem: "柠夏海风" }]
        },
        {
          title: "洗衣机清洁剂6",
          label: "产品特点",
          content:
            "专研不同水质不同配方，针对性溶解特定残留物。6效合1——灭活流感病毒, 杀细菌、杀真菌、筒壁除垢、祛除内筒异味、清洁内筒，一瓶解决多种洗衣机清洁卫生问题。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title3: "精准洁净：",
          content3:
            "不同水质不同配方，有效解决不同水质中矿物沉积不同而带来的水垢差异，保证内筒的清洁效果。",
          title4: "留香祛味：",
          content4: "法国调香大师赋予柠夏海风，自然清新",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌均具有杀菌作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          label1: "产品规格",
          label2: "产品容量",
          label3: "产品香型",
          skuList: [
            { boxitem: "TDS < 250", imgIndex: 4 },
            { boxitem: "TDS200-450", imgIndex: 5 },
            { boxitem: "TDS > 400", imgIndex: 3 }
          ],
          skuSizeList: [{ boxitem: "250ml/瓶" }],
          skuTypeList: [{ boxitem: "柠夏海风" }]
        },
        {
          title: "衣物除菌液（芳香型）",
          label: "产品特点",
          content:
            "内外衣物深层杀菌，针对生活中的常见细菌、真菌、病毒有杀灭、灭活、抑制作用，香味清新淡雅。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title5: "强效抑菌：",
          content5: "抑菌率>99.9%",
          title3: "精准洁净：",
          content3:
            "高渗透性表活，进入衣物纤维内部高效杀菌。",
          title4: "留香祛味：",
          content4: "法国调香大师特调纯白花园香调，留香祛味",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌、阴道加德纳菌、海氏肠球菌均具有抗菌作用。对大肠杆菌、白色念珠菌均具有抑菌作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          // label1: "产品规格",
          label2: "产品容量",
          label3: "产品香型",
          // skuList: [
          //   { boxitem: "TDS < 250", imgIndex: 4 },
          //   { boxitem: "TDS200-450", imgIndex: 5 },
          //   { boxitem: "TDS > 400", imgIndex: 3 }
          // ],
          skuSizeList: [{ boxitem: "500ml/瓶" }],
          skuTypeList: [{ boxitem: "纯白花园" }]
        },
        {
          title: "冰箱除菌除味剂",
          label: "产品特点",
          content:
            "强效除菌除味，针对冰箱常见细菌、真菌、病毒有杀灭、灭活、抑制作用，从根源抑制细菌、真菌、病毒引起的异味滋生，成分温和安全。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title5: "强效抑菌：",
          content5: "抑菌率>99.9%",
          title3: "高效洁净：",
          content3:
            "对冰箱残留的油污、汤汁、灰尘等，均有清洁作用。",
          title4: "留香祛味：",
          content4: "强大的除味功能，能祛除冰箱各类食材、香料等散发或残留的异味。",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌、沙门氏菌、李斯特菌、黑曲霉菌均具有杀菌作用。对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌、沙门氏菌、李斯特菌、黑曲霉菌均具有抑菌作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          // label1: "产品规格",
          label2: "产品容量",
          // label3: "产品香型",
          // skuList: [
          //   { boxitem: "TDS < 250", imgIndex: 4 },
          //   { boxitem: "TDS200-450", imgIndex: 5 },
          //   { boxitem: "TDS > 400", imgIndex: 3 }
          // ],
          skuSizeList: [{ boxitem: "250ml/瓶" }],
          // skuTypeList: [{ boxitem: "纯白花园" }]
        }
      ],
      imgIndex: 0,
      currentSkuIndex: 0,
      currentSizeIndex: -1,
      currentTypeIndex: -1
    };
  },

  methods: {
    selectimg(imgIndex, skuIndex) {
      this.imgIndex = imgIndex;
      this.currentSizeIndex = -1;
      this.currentSkuIndex = -1;
      this.currentTypeIndex = -1;
      this.currentSkuIndex = skuIndex;
    },
    goSelSku(index, imgIndex) {
      this.currentSkuIndex = index;
      this.imgIndex = imgIndex;
    },
    goSelSkuSize(index) {
      this.currentSizeIndex = index;
    },
    goSelSkuType(index) {
      this.currentTypeIndex = index;
    },
    openTianMao() {
      window.open("https://u2xihu.tmall.com");
    },
    openJingDong() {
      window.open('https://mall.jd.com/index-14078950.html?from=pc');
    }
  }
};
</script>

<style lang="scss" scoped>
.section2 {
  position: relative;
  background: #fff;
  height: 18.7rem;
  margin-top: 0.3rem;
  .prdimgs {
    position: absolute;
    left: 5%;
    width: 30%;
    height: 100%;
    overflow-y: auto;

    .prdimgitem {
      width: 97%;
      height: 3.6rem;
      margin-bottom: 0.5rem;
      border: 1px solid #e5e5e5;

      img {
        width: 80%;
        margin-top: 10%;
        margin-left: 10%;
      }
    }

    .prdimgitem-checked {
      border: 1px solid #000000;
    }
  }

  .prdtext {
    // width: 4.5rem;
    position: absolute;
    left: 4.2rem;
    color: #212121;
    display: grid;
    align-content: center;
    justify-content: start;
    align-items: center;
    justify-items: start;

    .title {
      font-size: 0.36rem;
      line-height: 0.9rem;
    }

    .label {
      color: #dfb25f;
      font-size: 0.2rem;
      line-height: 0.6rem;
    }

    .content {
      color: #212121;
      font-size: 0.25rem;
      width: 100%;
      line-height: 0.4rem;
    }

    .tip {
      color: #666666;
      font-size: 0.2rem;
      width: 100%;
    }

    .empty {
      height: 0.4rem;
    }

    .box {
      color: #999999;
      font-size: 0.3rem;
      line-height: 0.5rem;
      width: 100%;

      .boxitem {
        border: 1px solid #999999;
        padding: 0.06rem 0.15rem;
        border-radius: 10px;
        margin-right: 0.1rem;
        width: 40%;
        cursor: pointer;
        margin-bottom: 0.1rem;
        text-align: center;
        display: inline-block;
      }
      .boxitem-checked {
        background: #dfb25f;
        border: 1px solid #dfb25f;
        color: #fff;
      }

      .btn {
        background: #e5e5e5;
        border: 1px solid #e5e5e5;
        //padding: 0.06rem 0.24rem;
        border-radius: 10px;
        margin-right: 0.2rem;
        cursor: pointer;

        width: 2rem;
        height: 0.7rem;
        padding-right: 0.2rem;

        &:hover {
          background: #b2b2b2;
          color: #fff;
        }
      }

      .btnimg {
        height: 0.3rem;
        margin-right: 0.1rem;
        vertical-align: middle;
      }
    }
  }
}
</style>
